import React from 'react'

const Footer = () => (
  <footer className="footer">
    <div className="ef-container footer__inner">
      <div className="ef-row -x-between">
        <div className="ef-col -s-shrink u-s-mb-l u-m-mb-0">
          <a
            href="https://www.ef.edu/"
            className="footer__main-link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Education First"
          >
            <span className="ef-logo" />
          </a>
        </div>
        <div className="ef-col -s-12 -m-shrink">
          <div className="ef-row">
            <div className="ef-col -s-12 -m-shrink u-s-mb-l u-m-mb-0">
              <a
                href="https://careers.ef.com/privacy-notice/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy policy
              </a>
            </div>
            <div className="ef-col -s-12 -m-shrink u-s-mb-xl u-m-mb-0">
              <a
                className="u-m-ml-l"
                href="https://www.ef.com/wwen/legal/cookie-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookies
              </a>
            </div>
            <div className="ef-col -s-12 -m-shrink">
              <span className="u-m-ml-xxl">
                © Signum International AG {new Date().getFullYear()}. All rights
                reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
