import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './Header/header'
import Footer from './Footer/footer'
import '../styles/main.scss'

const Layout = ({ children, settings, headerModifiers }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <>
        <Header
          settings={settings && settings.content}
          siteTitle={data.site.siteMetadata.title}
          siteDescription={data.site.siteMetadata.description}
          modifiers={headerModifiers}
        />
        <div>{children}</div>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.object,
  headerModifiers: PropTypes.string,
}

export default Layout
