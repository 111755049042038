import PropTypes from 'prop-types'
import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Location } from '@reach/router'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import Link from '../Link/link'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.headerRef = React.createRef()
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      import('@ef-global/web-ui/js/ef-header/ef-header').then(efheader => {
        new efheader.EfHeader(this.headerRef.current)
        document.body.classList.remove('-no-scroll')
      })
    }
  }

  render() {
    return (
      <header
        className={`ef-header -condensed 
          ${this.props.modifiers}
          `}
        data-type="condensed"
        data-trigger="#hero"
        ref={this.headerRef}
      >
        <div className="ef-header__main">
          <div className="ef-container ef-header__group -standalone">
            <div className="ef-header__item ef-header__product">
              <GatsbyLink className="ef-header__title" to="/">
                EF / Design
              </GatsbyLink>
            </div>
            <button
              className="ef-header__menu-toggle ef-nav__toggle"
              aria-label="Open menu"
            >
              <svg
                className="ef-icon ef-header__icon -animated -animated-menu"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
              >
                <g className="line1">
                  <rect
                    className="rect1"
                    y="9"
                    width="32"
                    height="3"
                    rx="1"
                    ry="1"
                  />
                </g>
                <g className="line2">
                  <rect
                    className="rect2"
                    y="20"
                    width="32"
                    height="3"
                    rx="1"
                    ry="1"
                  />
                </g>
              </svg>
            </button>

            <div className="ef-header__group -nav">
              <nav className={`ef-nav ef-header__item`}>
                <ul className="ef-nav__menu">
                  {this.props.settings &&
                    this.props.settings.main_nav &&
                    this.props.settings.main_nav.map((navitem, index) => (
                      <Location key={index}>
                        {({ location }) => {
                          return (
                            <li
                              className={`ef-nav__item ${
                                location.pathname.replace('/', '') ===
                                  navitem.link.cached_url ||
                                (location.pathname === '/' &&
                                  navitem.link.cached_url === 'home')
                                  ? '-is-active'
                                  : ''
                              }`}
                            >
                              <Link
                                storyblokLink={navitem.link}
                                className="ef-nav__link"
                                activeClassName="-is-active"
                              >
                                {navitem.name}
                              </Link>
                            </li>
                          )
                        }}
                      </Location>
                    ))}
                  <li className="ef-nav__item u-m-ml-l">
                    <OutboundLink
                      href="https://gud.ef.design"
                      className="ef-nav__link"
                      aria-label="EF GUD 5.0"
                    >
                      GUD 5.0
                      <span className="ef-header__icon u-ml-xs ef-icon -locked-outlined" />
                    </OutboundLink>
                  </li>
                  {this.props.settings && this.props.settings.header_cta && (
                    <li className="ef-nav__item">
                      <Link
                        storyblokLink={this.props.settings.header_cta[0].link}
                        className="ef-nav__link"
                        target={
                          this.props.settings.header_cta[0].link.linktype ===
                            'url' && '_blank'
                        }
                      >
                        {this.props.settings.header_cta[0].name}
                        {this.props.settings.header_cta[0].link.linktype ===
                          'url' && (
                          <span className="ef-header__icon ef-icon -new-window u-ml-xs" />
                        )}
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
  settings: PropTypes.object,
  modifiers: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
  siteDescription: '',
  modifiers: '',
}

export default Header
